import React, { useEffect, Suspense } from "react";
import { connect } from "react-redux";
import App from "./App";
import { authRequest } from "./../auth/state/actions";
import { getUser } from "../auth/state/actions";
import Loading from "./common/loading";
import LandingPage from "../common/components/landingPage/LandingPage";
import {
  currentSemester,
  otherAdminsSemester,
  ROLES_enums,
} from "../common/constants";

const AppContainer = ({ auth, user, getUser, history }) => {
  const session = localStorage.getItem("user");
  useEffect(() => {
    session &&
      getUser(
        () => history.push("/"),
        [
          ROLES_enums.admission,
          ROLES_enums.admission_6,
          ROLES_enums.regview,
        ].includes(+user.role)
          ? localStorage.getItem("semester") || currentSemester
          : otherAdminsSemester,
        +user.role === ROLES_enums.applicant
      );
    // eslint-disable-next-line
  }, []);

  const renderApp = () => {
    return user ? <App /> : <Loading errors={auth.error} />;
  };

  return (
    <>
      {session ? (
        renderApp()
      ) : (
        <Suspense fallback={<Loading errors={["Loading..."]} />}>
          <LandingPage />
        </Suspense>
      )}
    </>
  );
  // Redirect
  // return <div>{session ? renderApp() : history.push("/applicant/login")}</div>;
};

const mapStateToProps = ({ auth }) => {
  return { user: auth.user, auth };
};

export default connect(mapStateToProps, { getUser, authRequest })(AppContainer);
