import axios from "axios";
import { API } from "../constants";

const asyncEmailValidate = async (values /*, dispatch */) => {
  const { data } = await axios.post(`${API}/auth/email/verify`, {
    email: values.email,
  });
  // eslint-disable-next-line
  if (!data.valid) throw { email: "emailUse" };
};

const asyncIDValidate = async (values /*, dispatch */) => {
  const savedApplication = Boolean(localStorage.getItem("register"));
  const newApplicant = Boolean(localStorage.getItem("oldRecord"));

  const session = localStorage.getItem("user");
  {
    const { data } = await axios.get(
      `${API}/auth/national-id/verify?national_id=${values.national_id}`,
      {
        headers: { "auth-session": session },
      }
    );

    // eslint-disable-next-line
    if (!data.valid) throw { national_id: "nationalUse" };
    if (data.valid && data.record && !savedApplication && !newApplicant) {
      // localStorage.setItem("modal", "true")
      const applicantInfo = {
        ...data.record,
        arabic_speaker: data?.record?.arabic_speaker ? "1" : "0",
        phone: (data?.record?.phone ? "" + data?.record?.phone : "")?.substring(
          3
        ),
        area_code: (data?.record?.home_phone
          ? "" + data?.record?.home_phone
          : ""
        )?.substring(3, 5),
        home_phone: (data?.record?.home_phone
          ? "" + data?.record?.home_phone
          : ""
        )?.substring(5),
        superior_phone: (data?.record?.superior_phone
          ? "" + data?.record?.superior_phone
          : ""
        )?.substring(3),
        emergency_phone: (data?.record?.emergency_phone
          ? "" + data?.record?.emergency_phone
          : ""
        )?.substring(3),
        // applicantEducationState: data?.record?.previous_university ? 2 : data?.record?.tagseer_institute ? 3 : 1,
      };

      localStorage.setItem("register", JSON.stringify(applicantInfo));
      data.record !== null &&
        localStorage.setItem("oldRecord", JSON.stringify(applicantInfo));

      !newApplicant && window.location.reload();
    }
  }
};

export default {
  asyncEmailValidate,
  asyncIDValidate,
};
