import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from "@material-ui/core/styles";
import { primaryColor } from "../../../common/assets/jss/appStyles";

const useStyls = makeStyles(() => ({
  root: {
    margin: "10px auto",
    "& label.Mui-focused": {
      color: primaryColor,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: primaryColor,
      },
    },
  },
  inputContainer: {
    maxWidth: 500
  },
  fullWidth: {
    maxWidth: '100%'
  }
}));

export default function MuiAutoComplete({ val, options, label, name, input, placeholder, disabled, fullWidth, required, meta: { touched, invalid, error } }) {
  const [t, i18n] = useTranslation();
  const classes = useStyls();
  let selectedOption = val && options?.filter(op => {
    return Object.values(op).includes(val.label)
  })
  const [option, setOption] = useState(selectedOption && selectedOption.length > 0 ? selectedOption[0] : val || { label: "" })

  return (
    <Autocomplete
      options={options ? options : []}
      getOptionSelected={(option, value) => {
        return true;
      }}
      value={option}
      onChange={(e, newOption) => setOption(newOption)}
      getOptionLabel={(option) => (i18n.language === "en" ? option.label || option.arabic_label : option.arabic_label || option.label) || ""}
      className={fullWidth ? classes.fullWidth : classes.inputContainer}
      renderInput={(params) => <TextField className={classes.root} variant="outlined"  {...params} placeholder={placeholder}
        required={required} label={label} disabled={disabled} helperText={touched && error && t(`validation.${error}`)}
        error={touched && invalid} {...input} />}
    />
  );
}

