import React, { useState } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { useTranslation } from "react-i18next";
// Actions
import { authRequest, cleanErrors } from "./../../state/actions";
import { signIn } from "../../state/actions";
// @Material-UI
import Zoom from "@material-ui/core/Zoom";
import { Grid, Paper, Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
// Assets
import MuiTextField from "../../../common/components/muiTextField/MuiTextField";
import { validate } from "./../../../common/helper/validate";
import Logo from "../../../common/assets/images/Logo-sm.png";
// Styles
import { useStyles } from "./loginStyles";

const Login = ({ handleSubmit, signIn, history, auth, authRequest, cleanErrors }) => {
  const classes = useStyles();
  const user = localStorage.getItem("user");

  // Language Control
  const { t, i18n } = useTranslation();
  const defaultLang = localStorage.getItem("i18nextLng");
  const [selected, setSelected] = useState(defaultLang);
  // Language Control
  const handleClick = (lang) => {
    let msg = lang === "ar" ? "تم تغير اللغة الي" : "Language Changed to";
    let language = lang === "ar" ? "العربية" : "English"
    toast.success(`${msg} ${language}`, { autoClose: 3000, });
    i18n.changeLanguage(lang);
    setSelected(lang);
  };

  const onSubmit = (values) => {
    authRequest();
    signIn(values, () => history.push("/"));
  };

  const renderLogin = () => (
    <div className={classes.login}>
      <Grid container justify="center">
        <Grid className={classes.inner}>
          <Zoom in={true} mountOnEnter unmountOnExit>
            <Paper elevation={3} className={classes.loginBox}>
              <div className={classes.div_lang}>
                <button
                  onClick={() => handleClick("en")}
                  className={`${classes.lang} ${selected === "en" && "selected"}`}
                >
                  EN
                </button>
                <button
                  onClick={() => handleClick("ar")}
                  className={`${classes.lang} ${selected === "ar" && "selected"}`}
                >
                  عربي
                </button>
              </div>
              <div className={classes.head}>
                <img className={classes.logo} src={Logo} alt="logo" />
              </div>
              <div className={classes.hrbreak}></div>
              <div className={classes.body}>
                <form
                  className={classes.form}
                  onSubmit={handleSubmit((values) => onSubmit(values))}
                >
                  <Field name="email" type="email" label={t('login.email')} required component={MuiTextField} />
                  <Field name="password" type="password" required label={t('login.password')} component={MuiTextField} />
                  <div className={classes.wrapper}>
                    <Button
                      variant="contained"
                      className={classes.submit}
                      color="primary"
                      disabled={auth.loading}
                      type="submit"
                    >
                      {t('login.login')}
                    </Button>
                    {auth.loading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </form>
              </div>
              <Link to={"/forgot"} className={classes.forgot}>
                {t('login.forget')}
              </Link>
            </Paper>
          </Zoom>
        </Grid>
      </Grid>
    </div>
  );

  const redirect = () => history.push("/");

  return <div>{user ? redirect() : renderLogin()}</div>;
};

const mapStateToProps = ({ auth }) => ({ auth })

export default compose(
  reduxForm({ form: "login", validate }),
  connect(mapStateToProps, {
    signIn,
    authRequest,
    cleanErrors,
  })
)(Login);
