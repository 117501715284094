import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { API } from "../../../common/constants";
import { reduxForm } from "redux-form";
import { compose } from "recompose";
import { connect, useDispatch } from "react-redux";
import {
  Grid,
  Paper,
  Button,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { Backdrop, Zoom, Stepper } from "@material-ui/core";
import { Step, StepLabel } from "@material-ui/core";
import { Cached, AddCircle, ChromeReaderMode } from "@material-ui/icons";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";

import { toast } from "react-toastify";
import { WatchLater } from "@material-ui/icons";
import SuccessBar from "../../../common/components/snackbar/SuccessBar";
import { useTranslation } from "react-i18next";
import { validate } from "../../../common/helper/validate";
import Logo from "../../../common/assets/images/Logo-sm.png";
import { useStyles } from "./registerStyles";
import { authRequest, getAllMajors, signUp, getAllLookUps } from "./../../state/actions";
import { openBachelorCourse } from '../../../common/constants';
// steps
import BasicInfo from "./steps/BasicInfo";
import PersonalInfo from "./steps/PersonalInfo";
import EducationInfo from "./steps/Education";
import ParentInfo from "./steps/ParentInfo";
import Survey from "./steps/Survey";
import UploadDocumentsForm from "./steps/UploadDocuments";
import Registred from "../messages/Registred";
// pdf
import PDF from "../../../common/components/PDF/RegisterationPDF";
import { getcityCode } from "../../../common/helper/selecteLists";
import { jsPDF } from "jspdf";

const Register = ({
  handleSubmit,
  signUp,
  auth,
  form,
  history,
  authRequest,
  phoneValidate
}) => {
  const dispatch = useDispatch();
  const [disableButton, setdisableButton] = useState(true);
  const user = localStorage.getItem("user");
  const { t, i18n } = useTranslation();
  const isArabicLang = i18n.language === "ar";
  let doc = new jsPDF({});
  const errorMessage =
    auth.error.length > 1
      ? auth.error[i18n.language === "ar" ? 1 : 0]
      : auth.error[0];
  const Savedform = JSON.parse(localStorage.getItem("register"));
  const classes = useStyles();
  const submit = useRef(null);
  const [displayForm, setDisplayForm] = useState(Boolean(!Savedform));
  const [success, setSuccess] = useState(false);
  const [newApplication, setNewApplication] = useState(Boolean(!Savedform));
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const savedApplication = Boolean(localStorage.getItem("register"));
  const oldRecord = Boolean(localStorage.getItem("oldRecord"));

  // Language Control
  const defaultLang = localStorage.getItem("i18nextLng");
  const [selected, setSelected] = useState(defaultLang);
  useEffect(() => {
    dispatch(getAllLookUps('countries'));
    dispatch(getAllLookUps('cities'));
    dispatch(getAllLookUps('zones'));
    dispatch(getAllLookUps('schools'));
    dispatch(getAllMajors());
  }, []);
  useEffect(() => {
    if (form?.register?.values?.majors) {
      setdisableButton(false)
    }
  }, [form])
  const handleClick = (lang) => {
    i18n.changeLanguage(lang);
    setSelected(lang);
  };

  const handleOpen = () => window.open(doc.output("bloburl"), "_blank");

  const handleLocalSave = async () => {
    localStorage.removeItem("register");

    let register_form = form.register.values;
    // Async Email Check
    try {
      const { data } = await axios.post(`${API}/auth/email/verify`, {
        email: form.register?.values?.email,
      });

      if (!data.valid) delete register_form.email;
    } catch (err) {
      delete register_form.email;
    }

    let errors = Object.keys(validate(register_form));
    let formArr = Object.keys(register_form);
    if (
      errors.includes(register_form.home_phone) ||
      errors.includes(register_form.area_code)
    ) {
      delete register_form.area_code;
      delete register_form.home_phone;
    }
    for (let i = 0; i < formArr.length; i++) {
      if (errors.includes(formArr[i])) {
        delete register_form[formArr[i]];
      }
    }
    localStorage.setItem("register", JSON.stringify(register_form));
    setSuccess(true);
  };

  const handle = (val) => {
    val && localStorage.removeItem("register");
    // val && localStorage.setItem("new_applicant", form.register.values.national_id )
    // val && localStorage.setItem("noCheckID", true)
    setNewApplication(val);
    setDisplayForm(true);
  };

  const nextStep = () => {
    submit.current.click();
  };

  const handleNext = () => {
    // if(activeStep === 1 && form.register?.values?.degree === "PG" & openBachelorCourse){
    //   toast.error(t("The master course is not open yet in university"), { autoClose: 3000 });
    // }
    // if(activeStep === 1 && form.register?.values?.degree === "UG" & !openBachelorCourse){
    //   toast.error(t("The bachelor course is not open yet in university"), { autoClose: 3000 });
    // }
    // else{
    //   if ((activeStep < 5 && activeStep !== 1
    //   ) || (activeStep === 1)) {
    //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //   }
    // }

    if ((activeStep < 5 && activeStep !== 1) || (activeStep === 1)) setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function getSteps() {
    return [
      t("register.stepper.basic"),
      t("register.stepper.personal"),
      t("register.stepper.edu"),
      t("register.stepper.parent"),
      t("register.stepper.uploadDoc"),
      t("register.stepper.end"),
    ];
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <BasicInfo
            initialValues={!newApplication ? Savedform : {}}
            submitRef={submit}
            next={handleNext}
          />
        );

      case 1:
        return (
          <PersonalInfo
            initialValues={!newApplication ? Savedform : {}}
            submitRef={submit}
            next={handleNext}
          />

        );
      case 2:
        return (
          <EducationInfo
            initialValues={!newApplication ? Savedform : {}}
            submitRef={submit}
            next={handleNext}
          />
        );
      case 3:
        return (
          <ParentInfo
            initialValues={!newApplication ? Savedform : {}}
            submitRef={submit}
            next={handleNext}
          />
        );
      case 4:

        return (
          <UploadDocumentsForm
            next={handleNext}
            submitRef={submit}
            initialValues={!newApplication ? Savedform : {}}
            disableButton={setdisableButton}
          />
        )
      case 5:
        return (
          <Survey
            initialValues={!newApplication ? Savedform : {}}
            submitRef={submit}
            next={handleNext}
          />
        );
      // case 5:
      // return(
      //   <UploadDocuments
      //   initialValues={!newApplication ? Savedform : {}}
      //     submitRef={submit}
      //     next={handleNext}
      //     />
      // );
      default:
        return <Registred />;
    }
  }

  const onSubmit = (values) => {
    const formData = new FormData();
    if (activeStep >= 5) {
      // TODO: Refactor
      // clean data before request
      let reqData = values;
      if (form.register.values?.applicantEducationState === 1) reqData.applicant_type = "FS"
      if (form.register.values?.applicantEducationState === 2) reqData.applicant_type = "TS"
      if (form.register.values?.applicantEducationState === 3) reqData.applicant_type = "HD"
      if (values.health === "ok") delete reqData.health_type;
      if (values.previous === "no") {
        delete reqData.previous_university;
        delete reqData.previous_GPA;
        delete reqData.max_gpa;
        delete reqData.state_university;
      }
      if (values.tagsir === "no") {
        delete reqData.tagseer_institute;
        delete reqData.tagseer_department;
        delete reqData.tagseer_GPA;
        delete reqData.tagseer_year;
        delete reqData.tagseer_number;
        delete reqData.tagseer_date;
      }
      if (values.health_state === "ok") delete reqData.health_type;
      if (values.employment_state !== "work") delete reqData.employer;
      if (values.applicant_type === "FS") {
        delete reqData.transcript;
        delete reqData.diploma;
        delete reqData.SCFHS;
        delete reqData.courses;
      }
      if (form.register.values.sat_score) reqData.equation = +form.register.values.high_school_GPA * 0.3 + +form.register.values.sat_score * 0.7;
      else if (form.register.values.qiyas_aptitude && form.register.values.qiyas_achievement) reqData.equation = +form.register.values.high_school_GPA * 0.3 + +form.register.values.qiyas_aptitude * 0.3 + +form.register.values.qiyas_achievement * 0.4;
      else if (form.register.values.degree === "UG") reqData.equation = +form.register.values.high_school_GPA * 0.3;
      else if (form.register.values.degree === "PG") reqData.equation = null;

      // reqData.home_phone = values.area_code + values.home_phone
      const finalData = {
        ...reqData,
        ...reqData.majors,
        superior_phone: form.register.values.superior_phone && values.superior_code?.slice(1) + values.superior_phone,
        emergency_phone: form.register.values.emergency_phone && values.emergency_code?.slice(1) + values.emergency_phone,
        phone: form.register.values.code && values.code?.slice(1) + values.phone,
        zone_code: getcityCode(form.register?.values?.high_school_city, phoneValidate.cities)?.zone_code
      }
      delete finalData?.majors;
      delete finalData?.code;
      delete finalData?.superior_code;
      delete finalData?.emergency_code;
      delete finalData?.studentStatus;
      delete finalData?.scoreType;
      if (finalData.high_school_name === " ") {
        finalData.high_school_name = "Undefined - Others"
      }
      finalData.english_certf_score === 0 && delete finalData.english_certf_score
      Object.keys(finalData).forEach((k) => finalData[k] == null && delete finalData[k]);
      // if(form.register.values.english_certf_score === 0 && delete finalData?.english_certf_score)
      for (const key in finalData) {
        if (finalData.hasOwnProperty(key)) {
          formData.append(key, finalData[key]);
        }
      }
      authRequest();
      signUp(formData, () => history.push("/applicant/register/success"));
    }
  };

  const renderApplication = () => {
    return (
      <>
        <Typography
          align="center"
          variant="h4"
          gutterBottom
          style={{ marginTop: "15px", opacity: 0.8 }}
        >
          {t("register.titles.main")}
        </Typography>
        <Stepper
          style={{ flexWrap: "wrap" }}
          activeStep={activeStep}
          alternativeLabel
          connector={null}
          dir={isArabicLang ? "rtl" : "ltr"}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {<div className={classes.error}>{errorMessage}</div>}
        <div
          className={classes.form}
          onSubmit={handleSubmit((values) => onSubmit(values))}
        >
          <Grid container justify="center" spacing={2}>
            <Grid item xs={12}>
              {getStepContent(activeStep)}
            </Grid>
          </Grid>

          {activeStep > 0 && (
            <>
              <Button
                variant="contained"
                className={`${classes.submit} ${classes.submit_back}`}
                color="primary"
                disabled={auth.loading}
                // type="submit"
                onClick={handleBack}
              >
                {t("register.btn.back")}
              </Button>
            </>
          )}
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <Button
              variant="contained"
              className={`${classes.submit} ${classes.submit_next}`}
              color="primary"
              disabled={(activeStep === 4 ? disableButton : auth.loading)}
              // type="submit"
              onClick={nextStep}
            >
              {activeStep >= 5
                ? t("register.btn.finish")
                : t("register.btn.next")}
            </Button>
          </div>

          {activeStep <= 4 && activeStep > 0 && (
            <>
              <Button
                variant="contained"
                className={classes.save}
                color="primary"
                disabled={auth.loading}
                startIcon={<WatchLater />}
                // type="submit"
                onClick={handleLocalSave}
              >
                {t("register.btn.save")}
              </Button>

              {+activeStep === 5 && (
                <>
                  <Button
                    onClick={handleOpen}
                    startIcon={<ChromeReaderMode />}
                    variant="contained"
                    className={classes.download}
                  >
                    {t("register.btn.view")}
                  </Button>
                  <PDF
                    doc={doc}
                    data={form.register.values}
                    read={true}
                    major={""}
                  />
                </>
              )}
            </>
          )}

          <SuccessBar
            msg={t("register.titles.save")}
            success={success}
            close={() => setSuccess(false)}
          />
          <Backdrop className={classes.backdrop} open={auth.loading || false}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      </>
    );
  };

  const renderSavedApplication = () => {
    return (
      <div className={classes.preForm}>
        <div className={classes.preForm__inner}>
          {oldRecord && savedApplication && (
            <div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Typography className={classes.oldRecordNote}>
                  {t("register.titles.notification")}
                </Typography>
                <PriorityHighIcon className={classes.announcmentIcon} />
              </div>

              <Typography className={classes.oldRecordQuestion}>
                {t("register.titles.completequestion")}
              </Typography>
            </div>
          )}

          {Savedform && (
            <Button
              variant="contained"
              startIcon={<Cached />}
              color="primary"
              className={classes.preForm__buttons}
              onClick={() => handle(false)}
            >
              {oldRecord && savedApplication
                ? t("register.btn.reloadData")
                : t("register.btn.completeData")}
            </Button>
          )}

          <Button
            variant="contained"
            startIcon={<AddCircle />}
            color="primary"
            className={classes.preForm__buttons}
            onClick={() => handle(true)}
          >
            {oldRecord && savedApplication
              ? t("register.btn.registNewData")
              : t("register.btn.newregist")}
          </Button>
        </div>
      </div>
    );
  };

  const renderRegister = () => (
    <div className={classes.register}>
      <div className={classes.inner}>
        <Zoom in>
          <Paper elevation={3} className={classes.paper}>
            <div className={classes.langControl}>
              <button
                onClick={() => handleClick("en")}
                className={`${classes.lang} ${selected === "en" && "selected"}`}
              >
                EN
              </button>
              <button
                onClick={() => handleClick("ar")}
                className={`${classes.lang} ${selected === "ar" && "selected"}`}
              >
                عربي
              </button>
            </div>
            <div className={classes.head}>
              <img className={classes.logo} src={Logo} alt="logo" />
            </div>
            <div className={classes.hrbreak}></div>
            <div className={classes.body}>
              {/* {renderApplication()} */}
              {displayForm ? renderApplication() : renderSavedApplication()}
            </div>
            {i18n.language === "ar" ? <Typography align="center"

              gutterBottom
              style={{ opacity: 0.8, display: 'flex', justifyContent: 'center' }}>  +966 55 036 5067 : عزيزي مقدم الطلب إذا كان لديك أي مشاكل فنية يرجى التواصل معنا عبر الواتساب </Typography> : <Typography align="center"

                gutterBottom
                style={{ opacity: 0.8, display: 'flex', justifyContent: 'center' }}>Dear applicant if you have any technical issues please contact us through WhatsApp : +966 55 036 5067</Typography>}

          </Paper>
        </Zoom>

      </div>

    </div>
  );

  return <div>{user ? history.push("/") : renderRegister()}</div>;
};

const mapStateToProps = ({ auth, form, phoneValidate }) => ({ auth, form, phoneValidate });

export default compose(
  reduxForm({ form: "register", validate }),
  connect(mapStateToProps, { signUp, authRequest })
)(Register);
