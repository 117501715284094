export const HOST = window.location.origin;
export let API = "";
export let APIUniv = "";
export let BaseName = "portal";
export let APIRegis = `${HOST}/${BaseName}`;
export let SystemType = "";
export let currentSemester = +localStorage.getItem("semester") || 242;
export let otherAdminsSemester = 242;
export let currentSemesterApplicant = 242;
export let openBachelorCourse = false;
export let filterSemester = JSON.parse(
  localStorage.getItem("filterSemester")
) || [202, 211, 212, 221, 222, 231, 232, 241, 242];

if (process.env.PUBLIC_URL.includes("my.um.edu.sa")) {
  // production server
  API = "https://my.um.edu.sa/backend";
  APIUniv = "https://my.um.edu.sa/university/portal";
  SystemType = "Production";
} else if (process.env.PUBLIC_URL.includes("uat.um.edu.sa")) {
  // production server
  API = "https://uat.um.edu.sa/backend";
  APIUniv = "https://uat.um.edu.sa/university/portal";
  SystemType = "UAT";
} else if (process.env.PUBLIC_URL.includes("37.76.245.93") && !process.env.PUBLIC_URL.includes("37.76.245.93/test")) {
  // staging server
  API = "http://37.76.245.93/backend";
  APIUniv = "http://37.76.245.93/university/portal";
  SystemType = "Staging";
} else if (process.env.PUBLIC_URL.includes("dev.um.edu.sa")) {
  // devops server
  API = "http://dev.um.edu.sa/backend";
  APIUniv = "http://dev.um.edu.sa/university/portal";
  SystemType = "Devops";
} else {
  // develop server
  API = "https://my.um.edu.sa/backend";
  APIUniv = "https://my.um.edu.sa/university/portal";
  SystemType = "Production";

  // API = "https://uat.um.edu.sa/backend";
  // APIUniv = "https://uat.um.edu.sa/university/portal";
  // SystemType = "UAT";
}

export const ROLES = {
  1: "applicant",
  2: "admission",
  3: "english",
  4: "scholar",
  5: "supervisor",
  6: "admission",
  7: "deanPH",
  8: "communication",
  9: "deanM",
  10: "deanAS",
  11: "regview",
  12: "englishconf",
  13: "supervisor",
  14: "equation",
  15: "hod",
  90: "super",
  16: "registration",
  17: "deanGS",
};

export const ROLES_enums = {
  applicant: 1,
  admission: 2,
  english: 3,
  scholar: 4,
  supervisor: 5,
  admission_6: 6,
  deanPH: 7,
  communication: 8,
  deanM: 9,
  deanAS: 10,
  regview: 11,
  englishconf: 12,
  supervisor: 13,
  equation: 14,
  hod: 15,
  super: 90,
  registration: 16,
  deanGS: 17,
};
