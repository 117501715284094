import axios from "axios";
import { API } from "../../common/constants";

// configure axios
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";

export const studentLogin = async (credentials) => {
  const res = await axios.post(`${API}/auth/applicant/login`, credentials);
  localStorage.setItem("user", res.headers["auth-session"]);
  return res;
};

export const adminLogin = async (credentials) => {
  const res = await axios.post(`${API}/auth/admin/login`, credentials);
  localStorage.setItem("user", res.headers["auth-session"]);
  return res;
};

export const studentRegister = async (credentials) => {
  const res = await axios.post(`${API}/auth/register`, credentials);
  return res;
};

export const logOut = async () => {
  const session = localStorage.getItem("user");
  const res = await axios.post(
    `${API}/user/logout`,
    {},
    {
      headers: {
        "auth-session": session,
      },
    }
  );
  return res;
};

export const getCurrentUser = async (smster) => {
  const session = localStorage.getItem("user");

  const res = await axios.get(`${API}/auth/current?semester=${smster}`, {
    headers: {
      "auth-session": session,
    },
  });

  return res.data;
};

export const forgotPw = async (data) => {
  const res = await axios.post(`${API}/auth/applicant/forget`, data);

  return res;
};

export const verify = async (phone) => {
  const res = await axios.post(`${API}/applicant/verify/phone`, phone);

  return res;
};

export const setPassword = async (data) => {
  const session = localStorage.getItem("user");

  const res = await axios.post(`${API}/applicant/password/set`, data, {
    headers: {
      "auth-session": session,
    },
  });

  return res;
};

export const sendEmail = async (body) => {
  const session = localStorage.getItem("user");

  const res = await axios.post(`${API}/send/invitation`, body, {
    headers: {
      "auth-session": session,
    },
  });

  return res;
};

export const sendSMS_MSG = async (body) => {
  const session = localStorage.getItem("user");

  const res = await axios.post(
    `${API}/admission/applicant/sms/message?send_to=${body.send_to}`,
    body,
    {
      headers: {
        "auth-session": session,
      },
    }
  );

  return res;
};

export const resetPass = async (data, token) => {
  const res = await axios.put(
    `${API}/auth/applicant/password/reset?${token}`,
    data
  );
  return res;
};

export const getAnncmnt = async () => {
  const res = await axios.get(`${API}/anncmnt`);
  return res;
};

export const getMajors = async () => {
  const res = await axios.get(`${API}/major/all`);

  return res;
};

export const getLookUps = async (lookup) => {
  const res = await axios.get(`${API}/applicant/lookup?lookup=${lookup}`);
  return res;
};

export const addLookUps = async (lookup, body) => {
  const res = await axios.post(
    `${API}/applicant/lookup?lookup=${lookup}`,
    body
  );
  return res;
};
