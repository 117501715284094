export const majorList = [
  { val: "12", label: "NON" },
  { val: "1", label: "M" },
  { val: "2", label: "PH" },
  { val: "3", label: "NU" },
  { val: "4", label: "RT" },
  { val: "5", label: "EM" },
  { val: "6", label: "AT" },
  { val: "7", label: "HI" },
  { val: "8", label: "IS" },
  { val: "9", label: "CS" },
  { val: "10", label: "IE" },
  // { val: "11", label: "GSE" },
  // { val: "12", label: "NM" },
  { val: "13", label: "MEDN" },
  { val: "14", label: "MCP" },
  { val: "15", label: "MCS" },
  // { val: 11, label: "General science & English" },
];

export const PRIOS = [
  //{ name: "second_periority", id: 2 },
  { name: "third_periority", id: 3 },
  { name: "fourth_periority", id: 4 },
  { name: "fifth_periority", id: 5 },
  { name: "sixth_periority", id: 6 },
  { name: "seventh_periority", id: 7 },
  { name: "eighth_periority", id: 8 },
  { name: "nineth_periority", id: 9 },
  { name: "tenth_periority", id: 10 },
];

export const requiredPrios = [
  { name: "first_periority", id: 1 },
  { name: "second_periority", id: 2 },
];

export const requiredPrios_masters = [{ name: "first_periority", id: 1 }];

export const getMajorLabels = (value) => {
  // add  new master majors
  switch (value) {
    case "12":
      return "NON";
    case "1":
      return "M";
    case "2":
      return "PH";
    case "3":
      return "NU";
    case "4":
      return "RT";
    case "5":
      return "EM";
    case "6":
      return "AT";
    case "7":
      return "HI";
    case "8":
      return "IS";
    case "9":
      return "CS";
    case "10":
      return "IE";
    case "13":
      return "MEDN";
    case "14":
      return "MCP";
    case "15":
      return "MCS";
    default:
      return "";
  }
};

export const getPrioLabel = (name) => {
  switch (name) {
    case "first_periority":
      return "First";
    case "second_periority":
      return "Second";
    case "third_periority":
      return "Third";
    case "fourth_periority":
      return "Fourth";
    case "fifth_periority":
      return "Fifth";
    case "sixth_periority":
      return "Sixth";
    case "seventh_periority":
      return "Seventh";
    case "eighth_periority":
      return "Eighth";
    case "nineth_periority":
      return "Nineth";
    case "tenth_periority":
      return "Tenth";
    default:
      return "";
  }
};
