import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { reduxForm, change as changeFieldValue } from "redux-form";
import { applied_science_equ, medicine_equ, pharmacy_equ, validate } from "../../../../common/helper/validate";

import NewDocuments from "./NewDocuments";
// Material UI
import { Typography, Tooltip, IconButton, Button } from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import _ from 'lodash';
import { toast } from "react-toastify";
import {
  PRIOS,
  requiredPrios,
  requiredPrios_masters
} from "../../../../app/applicant/components/majors/majorList";
import SelectMenu from "../../../../app/applicant/components/majors/Select";
import { AddCircle } from "@material-ui/icons";
import ConfirmMajors from "../../../../app/applicant/components/majors/ConfirmMajors";
import asyncValidate from "../../../../common/helper/asyncValidate";
import { useStyles } from "../../../../app/applicant/components/majors/majorsStyles";
import { getAllMajors } from "../../../state/actions";
import HealthDocuments from "./HealthDocuments";
import TransferDocuments from "./TransferDocuments";
import MastersDocuments from "./MastersDocuments";

const UploadDocumentsForm = ({ handleSubmit, phoneValidate, submitRef, next, form, disableButton }) => {
  const classes = useStyles();
  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();
  const [systemOpenMajors, setSystemOpenMajors] = useState(0);
  const [documentsOpen, setDocumentsOpen] = useState(false);
  const [error, setError] = useState();
  const formValues = form?.register?.values || {};
  const {
    national_file, high_school, photo, high_school_GPA, qiyas_achievement, scoreType, engState, quadrant_file, english_certf_score, SAT_score,
    tahisli_file, academic_ielts, tofel, SAT, applicantEducationState, transcript, SCFHS, bachelor_degree_certificate, first_recommendation, diploma, step
  } = formValues;

  const submit = (values) => {
    if (applicantEducationState === 1) {
      if (national_file?.name && high_school?.name && photo?.name) {
        if (scoreType === 1 && engState === "close") {
          if (quadrant_file?.name && tahisli_file?.name) {
            setError(" ");
            next();
          }
        } else if (scoreType === 1 && engState === "open" && (english_certf_score > 0)) {
          if (quadrant_file?.name && tahisli_file?.name && (academic_ielts?.name || tofel?.name || step?.name)) {
            setError(" ");
            next();
          }
        } else if (scoreType === 2 && engState === "close" && SAT?.name) {
          setError(" ");
          next();
        } else if (scoreType === 2 && engState === "open" && (english_certf_score > 0) && SAT?.name) {
          if (academic_ielts?.name || tofel?.name || step?.name) {
            setError(" ");
            next();
          }
        }
      } else setError("Please upload the required files");
    }
    if (applicantEducationState === 2) {
      if (national_file?.name && high_school?.name && transcript?.name && photo?.name) {
        if (engState === "open" && (academic_ielts?.name || tofel?.name || step?.name) && (english_certf_score > 0)) {
          setError(" ");
          next();
        } else if (engState === "close") {
          setError(" ");
          next();
        }
      } else setError("Please upload the required files");
    }
    if (applicantEducationState === 3) {
      if (national_file?.name && high_school?.name && transcript?.name && diploma?.name && SCFHS?.name && photo?.name) {
        if (engState === "open" && (academic_ielts?.name || tofel?.name || step?.name) && (english_certf_score > 0)) {
          setError(" ");
          next();
        } else if (engState === "close") {
          setError(" ");
          next();
        }
      } else setError("Please upload the required files");
    }
    if (formValues?.degree === "PG") {
      if (selectedPrios.first_periority === 13 || selectedPrios.first_periority === 14) {
        if (bachelor_degree_certificate?.name && first_recommendation?.name && national_file?.name && transcript?.name && (engState === "open" ? ((academic_ielts?.name || tofel?.name || step?.name)) : true)) {
          setError(" ");
          next();
        }
        else toast.error("Please upload the required files")
      }
      if (selectedPrios.first_periority === 15) {
        if (bachelor_degree_certificate?.name && national_file?.name && transcript?.name && (engState === "open" ? ((academic_ielts?.name || tofel?.name || step?.name)) : true)) {
          setError(" ");
          next();
        }
        else toast.error("Please upload the required files")
      }
    }
  };

  useEffect(() => {
    dispatch(getAllMajors());
    setSystemOpenMajors(
      phoneValidate?.majors
        ?.filter((major) => major.name !== "NM" && major.name !== "GSE")
        .filter((major) =>
          formValues?.gender === "F"
            ? major.status === true
            : major.status_m === true
        )?.length
    );
  }, [phoneValidate?.majors]);

  const [maxmajors, setMaxMajors] = useState(formValues?.gender === "F" ? systemOpenMajors - 1 : systemOpenMajors);

  useEffect(() => {
    setMaxMajors(formValues?.gender === "F" ? systemOpenMajors - 1 : systemOpenMajors);
  }, [systemOpenMajors]);

  const [open, setOpen] = useState(false);
  const [selectedPrios, setSelectedPrios] = useState({});
  const [openPrios, setOpenPrios] = useState([]);
  const [lastPrio, setLastPrio] = useState(2);

  const handleChange = (prio) => {
    // add the selected value to object for later validation
    setSelectedPrios({ ...selectedPrios, [`${prio.name}`]: +prio.value });
  };

  const handleDelPrio = () => {
    // get the deleted periority name
    let unWantedPrio = PRIOS.filter((prio) => prio.id === lastPrio);
    // delete from selected periorities
    delete selectedPrios[unWantedPrio[0].name];
    // update last prio
    let nextPrio = lastPrio > 1 && lastPrio - 1;
    setLastPrio(nextPrio);
    // remove last opened prio from the opened list
    let prios = openPrios.filter((prio) => prio !== lastPrio);
    setOpenPrios(prios);
  };

  const handleAddPrio = () => {
    // update last prio
    // let nextPrio = lastPrio < maxmajors && lastPrio + 1;
    let nextPrio = lastPrio < maxmajors && lastPrio + 1;
    setLastPrio(nextPrio);
    // add last opened prio to the opened list
    setOpenPrios([...openPrios, nextPrio]);
  };

  const handleCancel = () => setOpen(false);

  function renderMaxMajors() {
    dispatch(getAllMajors());
    let equ;
    // high school applicant
    if (SAT_score) equ = SAT_score * 0.7 + high_school_GPA * 0.3;
    else equ = high_school_GPA && high_school_GPA * 0.3 + qiyas_achievement * 0.4 + formValues?.qiyas_aptitude * 0.3;

    // transfered applicant
    let previous_gpa = formValues?.previous_GPA;
    let max_gpa = formValues?.max_gpa;
    console.log({ max_gpa, previous_gpa, equ })

    // Females are not allowed to choose Industrial Engineering
    // if (formValues?.gender === "F") {
    //   setMaxMajors(maxmajors - 1);
    // }

    // Tagsir Applicant
    if (formValues?.tagseer_department) {
      const availablePrios = phoneValidate?.majors?.filter((prio) => [3, 4, 5, 6].includes(+prio.id))
        .filter((prio) => formValues?.gender === "F" ? prio.status === true : prio.status_m === true)
        .filter((prio) => (prio.name !== "GSE"));
      return setMaxMajors(availablePrios?.length);
    }

    // check the previous gpa
    // if the GPA base is 4
    // Medicine requires 3.5/4 or higher , Pharmacy requires 2.75/4 or higher & Applied Science requires 2/4 or higher
    if (+max_gpa === 4) {
      if (+previous_gpa >= 3.5 || +equ >= medicine_equ) setMaxMajors(maxmajors);
      else if (+previous_gpa >= 3 || +equ >= applied_science_equ) setMaxMajors(maxmajors - 2);
      else if (+previous_gpa >= 2.75 || +equ >= pharmacy_equ) setMaxMajors(maxmajors - 1);
      else setMaxMajors(1);
    } else if (+max_gpa === 5) {
      // if the GPA base is 5
      // Medicine requires 4.5/5 or higher , Pharmacy requires 3.75/5 or higher & Applied Science requires 3/5 or higher
      if (+previous_gpa >= 4.5 || +equ >= medicine_equ) setMaxMajors(maxmajors);
      else if (+previous_gpa >= 4 || +equ >= applied_science_equ) setMaxMajors(maxmajors - 2);
      else if (+previous_gpa >= 3.75 || +equ >= pharmacy_equ) setMaxMajors(maxmajors - 1);
      else setMaxMajors(1);
    } else if (equ) {
      if (+equ >= medicine_equ) setMaxMajors(maxmajors);
      else if (+equ >= pharmacy_equ) setMaxMajors(maxmajors - 1);
      else if (+equ >= applied_science_equ) setMaxMajors(maxmajors - 2);
      else setMaxMajors(1);
    } else setMaxMajors(1);
  }

  useEffect(() => {
    // calculate maximum priorites the applicant can select
    renderMaxMajors();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div style={{ color: "red " }}>{error}</div>
      <form onSubmit={handleSubmit((values) => submit(values))}>
        {(!documentsOpen) ? (
          <div className={classes.majorsInner}>
            <div className={classes.majorHeader}>
              <Typography variant="h5" align="center">
                {t("applicant.titles.prios")}
              </Typography>
            </div>
            {/* Mandatory Priorites */}
            {/* 2 required prios in general and 1 prio only for unqualified student! */}
            {formValues?.degree === "UG" ?
              (
                <>
                  {requiredPrios
                    .filter((prio) => {
                      if (maxmajors === 1) return prio.id === 1;
                      return prio;
                    })
                    .map((prio, index) => {
                      return (
                        <SelectMenu
                          key={index}
                          value={formValues?.majors}
                          user={{
                            ...formValues,
                            majors: phoneValidate?.majors?.sort(
                              (a, b) => a.id - b.id
                            ),
                            high_school: high_school_GPA,
                            tahsily: qiyas_achievement,
                            qudrat: formValues?.qiyas_aptitude,
                            GPA: formValues?.previous_GPA,
                            SAT: SAT_score,
                          }}
                          handleChange={handleChange}
                          name={prio.name}
                          selectedPrios={selectedPrios}
                          validate
                          form={formValues?.degree}
                          prefillValue={selectedPrios[prio.name]}
                        />
                      );
                    })}

                  {/* Optional Priorites*/}
                  {PRIOS.filter((prio) => openPrios.includes(prio.id)).map(
                    (prio, index) => {
                      return (
                        <div key={index} className={classes.selectContainer}>
                          <SelectMenu
                            user={{
                              ...formValues,
                              majors: phoneValidate.majors,
                              high_school: high_school_GPA,
                              tahsily: qiyas_achievement,
                              qudrat: formValues?.qiyas_aptitude,
                              GPA: formValues?.previous_GPA,
                              SAT: SAT_score,
                            }}
                            form={formValues?.degree}
                            handleChange={handleChange}
                            name={prio.name}
                            selectedPrios={selectedPrios}
                            validate
                            prefillValue={selectedPrios[prio.name]}
                            lastPrio={prio.id === lastPrio}
                            handleDelPrio={handleDelPrio}
                          />
                        </div>
                      );
                    }
                  )}
                  <IconButton
                    disabled={lastPrio >= maxmajors}
                    onClick={handleAddPrio}
                    aria-label="add prio"
                    className={classes.addPrio}
                    style={{ marginTop: "15px" }}
                  >
                    <AddCircle
                      className={`${classes.add} ${lastPrio >= maxmajors ? classes.disableAdd : ""
                        }`}
                    />
                  </IconButton>
                </>
              ) :
              (
                <>
                  {requiredPrios_masters
                    .filter((prio) => {
                      if (maxmajors === 1) return prio.id === 1;
                      return prio;
                    })
                    .map((prio, index) => {
                      return (
                        <SelectMenu
                          key={index}
                          value={formValues?.majors}
                          user={{
                            ...formValues,
                            majors: phoneValidate?.majors?.sort(
                              (a, b) => a.id - b.id
                            ),
                            high_school: high_school_GPA,
                            tahsily: qiyas_achievement,
                            qudrat: formValues?.qiyas_aptitude,
                            GPA: formValues?.previous_GPA,
                            SAT: SAT_score,
                          }}
                          handleChange={handleChange}
                          name={prio.name}
                          selectedPrios={selectedPrios}
                          validate
                          form={formValues?.degree}
                          prefillValue={selectedPrios[prio.name]}
                        />
                      );
                    })}
                </>
              )
            }
            {(_.isEmpty(selectedPrios)) ? (
              <>
                <Button
                  // disabled={(selectedPrios.first_periority !== 12 || selectedPrios.first_periority !== 0 ) ? false   : true}
                  disabled={true}
                  onClick={() => setOpen(true)}
                  className={classes.button}
                  variant="contained"
                  color="primary"
                >
                  {t("applicant.majors.buttons.save")}
                </Button>
              </>
            )
              :
              (
                <>
                  <Button
                    // disabled={(selectedPrios.first_periority !== 12 || selectedPrios.first_periority !== 0 ) ? false   : true}
                    disabled={(((selectedPrios.first_periority === 12 || selectedPrios.first_periority === 0)) ? true : false)}
                    onClick={() => setOpen(true)}
                    className={classes.button} no firest priority
                    variant="contained"
                    color="primary"
                  >
                    {t("applicant.majors.buttons.save")}
                  </Button>
                </>
              )
            }

            <ConfirmMajors
              open={open}
              confirm={() => {
                setDocumentsOpen(true);
                disableButton(false);
                dispatch(changeFieldValue("register", "majors", selectedPrios));
              }}
              cancel={handleCancel}
            />
          </div>
        ) : (
          <>
            {applicantEducationState === 1 && formValues?.degree === "UG" && (
              <NewDocuments />
            )}
            {applicantEducationState === 2 && formValues?.degree === "UG" && (
              <TransferDocuments />
            )}
            {applicantEducationState === 3 && formValues?.degree === "UG" && (
              <HealthDocuments />
            )}
            {formValues?.degree === "PG" && (
              <MastersDocuments selectedPrios={selectedPrios} />
            )}
            {/* {(scoreType === 1 && (applicantEducationState === 2 || applicantEducationState === 3)) && (
          <><MuiUploadField
                  add={addFile}
                  remove={removeFile}
                  
                  name="quadrant_file"
                  label={"Quadrat File"}
                  // description={t("applicant.uploadForm.inputs.tooltips.ssn")}
                  file={quadrant_file} />
                  
                  <MuiUploadField
                    add={addFile}
                    remove={removeFile}
                    // required
                    name="tahisli_file"
                    label={"Tahsili File"}
                    // description={t("applicant.uploadForm.inputs.tooltips.ssn")}
                    file={tahisli_file} /></>
        )} */}
          </>
        )}
        <button
          disable
          type="submit"
          ref={submitRef}
          style={{ display: "none" }}
        ></button>
      </form>
    </div>
  );
};

const mapStateToProps = ({ form, phoneValidate }) => ({ form, phoneValidate });

export default reduxForm({
  form: "register",
  destroyOnUnmount: false,
  validate,
  asyncValidate: asyncValidate.asyncIDValidate,
})(connect(mapStateToProps)(UploadDocumentsForm));
