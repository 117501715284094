import {
  studentRegister,
  studentLogin,
  adminLogin,
  getAnncmnt,
  forgotPw,
  getCurrentUser,
  verify,
  setPassword,
  sendEmail,
  resetPass,
  getMajors,
  // logOut,
  sendSMS_MSG,
  getLookUps,
  addLookUps,
} from "../services/authServices";
import Types from "../state/types";
import { toast } from "react-toastify";
import axios from "axios";
import { API, currentSemesterApplicant } from "../../common/constants";

const handleError = (err, type, dispatch, showToast = true, warn, redirect) => {
  let lang = localStorage.getItem("i18nextLng");
  let sessionErr =
    lang === "en"
      ? "Session Time Out. Please Try To Login Again"
      : "انتهت صلاحية الجلسة. من فضلك اعد تسجيل الدخول";
  if (err.response) {
    if (err.response.status >= 500) {
      showToast &&
        toast[warn ? "warn" : "error"](
          lang === "en" ? "Connection Error" : "خطأ في الإتصال"
        );
      dispatch({ type, payload: ["Connection Error", "خطأ في الإتصال"] });
    } else if (err.response.status === 403) {
      localStorage.removeItem("user");
      redirect ? redirect() : window.location.reload();
      toast.warn(sessionErr);
    } else if (err.response.status >= 400) {
      showToast &&
        toast[warn ? "warn" : "error"](
          typeof err.response.data === "object"
            ? Object.values(err.response.data).length === 2
              ? Object.values(err.response.data)[
                  lang === "ar" ? 1 : 0
                ].toString()
              : Object.values(err.response.data)[0].toString()
            : [err.response.data]
        );
      dispatch({
        type,
        payload:
          typeof err.response.data === "object"
            ? Object.values(err.response.data)
            : [err.response.data],
      });
    }
  } else {
    showToast &&
      toast[warn ? "warn" : "error"](
        lang === "en" ? "Connection Error" : "خطأ في الإتصال"
      );
    dispatch({ type, payload: ["Connection Error", "خطأ في الإتصال"] });
  }
};

// *========> Profile <========*
export const updatingInfo = () => ({ type: Types.UPDATE_REQUEST });
export const cleanInfoErrors = () => ({ type: Types.UPDATE_CLEAN });

export const updateInfo = (newInfo, redirect) => async (dispatch) => {
  const session = localStorage.getItem("user");

  try {
    // eslint-disable-next-line
    const { data } = await axios.put(`${API}/role/profile`, newInfo, {
      headers: {
        "auth-session": session,
      },
    });
    redirect && redirect();
  } catch (err) {
    handleError(err, Types.UPDATE_ERROR, dispatch);
  }
};

// *========> Majors <========*
export const updatingMajors = () => ({ type: Types.UPDATE_REQUEST });

export const updateMajors = (newMajors, redirect) => async (dispatch) => {
  const session = localStorage.getItem("user");
  try {
    // eslint-disable-next-line
    const { data } = await axios.put(
      `${API}/admission/majors/disable`,
      newMajors,
      {
        headers: {
          "auth-session": session,
        },
      }
    );
    dispatch({ type: Types.UPDATE_SUCCESS });
    redirect && redirect();
  } catch (err) {
    handleError(err, Types.UPDATE_ERROR, dispatch);
  }
};

// Roles
export const updateRoles = (id, redirect) => async (dispatch) => {
  const session = localStorage.getItem("user");
  let lang = localStorage.getItem("i18nextLng");
  try {
    const { data } = await axios.put(`${API}/role/update`, id, {
      headers: {
        "auth-session": session,
      },
    });
    dispatch({ type: Types.UPDATE_SUCCESS, payload: data });
    redirect && redirect();
    toast.success(Object.values(data)[lang === "en" ? 0 : 1].toString());
  } catch (err) {
    handleError(err, Types.UPDATE_ERROR, dispatch, true, false);
  }
};

export const authRequest = () => ({ type: Types.AUTH_REQUEST });
export const setPwRequest = () => ({ type: Types.SET_PW_REQUEST });
export const cleanErrors = () => ({ type: Types.AUTH_CLEAN });

export const signUp = (values, redirect) => async (dispatch) => {
  try {
    const { data } = await studentRegister(values);
    localStorage.setItem("data", JSON.stringify(data));
    // redirect to success page
    redirect && redirect();
    // remove any saved application
    localStorage.removeItem("register");
    dispatch({
      type: Types.AUTH_USER,
      payload: false,
    });
  } catch (err) {
    handleError(err, Types.AUTH_ERROR, dispatch);
  }
};

// Login the applicant into the system
export const signIn = (values, redirect) => async (dispatch) => {
  try {
    // eslint-disable-next-line
    const { data } = await studentLogin(values);
    redirect && redirect();
  } catch (err) {
    handleError(err, Types.AUTH_ERROR, dispatch, true);
  }
};

// Login the Admin into the system
export const adminSignIn = (values, redirect) => async (dispatch) => {
  try {
    // eslint-disable-next-line
    const { data } = await adminLogin(values);
    redirect && redirect();
  } catch (err) {
    handleError(err, Types.AUTH_ERROR, dispatch, true);
  }
};

// Logout the user from the system
export const signOut = (redirect) => async (dispatch) => {
  try {
    //const { data } = await logOut();
    localStorage.removeItem("user");
    redirect && redirect();
    dispatch({
      type: Types.AUTH_CLEAN,
      payload: false,
    });
  } catch (err) {
    handleError(err, Types.AUTH_ERROR, dispatch);
  }
};

export const forgot = (values, redirect) => async (dispatch) => {
  try {
    // eslint-disable-next-line
    const { data } = await forgotPw(values);
    redirect && redirect();
    dispatch({
      type: Types.AUTH_USER,
      payload: false,
    });
  } catch (err) {
    handleError(err, Types.AUTH_ERROR, dispatch);
  }
};

export const resetPassword = (token, values, redirect) => async (dispatch) => {
  try {
    // eslint-disable-next-line
    const { data } = await resetPass(token, values);
    redirect && redirect();
    dispatch({
      type: Types.AUTH_USER,
      payload: false,
    });
  } catch (err) {
    handleError(err, Types.AUTH_ERROR, dispatch);
  }
};

// get current user logged in
export const getUser = (redirect, smster, isApplicant) => async (dispatch) => {
  try {
    const user = await getCurrentUser(
      isApplicant ? +currentSemesterApplicant : smster
    );
    dispatch({
      type: Types.AUTH_USER,
      payload: user,
    });
  } catch (err) {
    handleError(err, Types.AUTH_ERROR, dispatch, false, false, redirect);
  }
};

// admin announcements
export const getAnnouncements = () => async (dispatch) => {
  try {
    const { data } = await getAnncmnt();

    dispatch({
      type: Types.GET_ANCCMNT,
      payload: data,
    });
  } catch (e) {}
};

export const getAllMajors = () => async (dispatch) => {
  try {
    const { data } = await getMajors();
    dispatch({
      type: Types.GET_ALL_MAJORS,
      payload: data.majors,
    });
  } catch (e) {}
};

export const getAllLookUps = (type) => async (dispatch) => {
  try {
    const { data } = await getLookUps(type);
    dispatch({
      type: Types.GET_ALL_LOOKUP,
      payload: { name: `${type}`, data: data },
    });
  } catch (e) {}
};

export const addLookUp = (type, body) => async (dispatch) => {
  try {
    await addLookUps(type, body);
    //  await getAllLookUps(type)
  } catch (e) {}
};

export const phoneRequest = () => ({ type: Types.GET_CODE_REQUEST });

export const verifyPhone = (phone) => async (dispatch) => {
  try {
    const { data } = await verify(phone);
    dispatch({
      type: Types.GET_CODE_SUCCESS,
      payload: data.code,
    });
  } catch (err) {
    handleError(err, Types.GET_CODE_ERROR, dispatch);
  }
};

export const setPWFirstTime = (password) => async (dispatch) => {
  try {
    const { data } = await setPassword(password);
    dispatch({
      type: Types.AUTH_USER,
      payload: data,
    });
  } catch (err) {
    handleError(err, Types.SET_PW_ERROR, dispatch);
  }
};

// Zoom Link Invite
export const sendingLink = () => ({ type: Types.SEND_INVITE_REQUEST });
export const clean = () => ({ type: Types.INVITE_CLEAN });
export const sendLink = (link, callback, success) => async (dispatch) => {
  try {
    const res = await sendEmail(link);
    dispatch({ type: Types.SEND_INVITE_SUCCESS });
    clean();
    callback();
    success(res);
  } catch (err) {
    handleError(err, Types.SEND_INVITE_ERROR, dispatch, true);
  }
};

// SMS
export const sendingSMS = () => ({ type: Types.SEND_INVITE_REQUEST });
export const sendSMS = (msg, callback, success) => async (dispatch) => {
  try {
    const res = await sendSMS_MSG(msg);
    dispatch({ type: Types.SEND_INVITE_SUCCESS });
    clean();
    callback();
    success(res);
  } catch (err) {
    handleError(err, Types.SEND_INVITE_ERROR, dispatch, true);
  }
};
